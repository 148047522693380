import React, {Fragment, useState, useCallback} from 'react';
import {useForm, useFormState, Form} from 'react-final-form';
import {
  Button,
  useCreate,
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import IconSave from '@material-ui/icons/Save';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {CircularProgress} from '@material-ui/core';

const QuickCreateButton = props => {
  const { onCreate, source, title, children, resource } = props;
  const [showDialog,
    setShowDialog] = useState(false);
  const [create, {
      loading
    }
  ] = useCreate(resource);
  const form = useForm();
  const formState = useFormState();
  const currentFieldValue = formState.values ? formState.values[props.source] : undefined;
  const handleSubmit = useCallback(data => {
    create({
      payload: {
        data
      }
    }, {
      onSuccess: ({data: newRecord}) => {
        if (onCreate) {
          onCreate(newRecord, currentFieldValue);
        } else {
          form.change(source, newRecord.id);
        }
        setShowDialog(false);
      }
    });
  }, [form, currentFieldValue, create, source, onCreate]);
  return (
    <Fragment>
      <Button onClick={() => setShowDialog(true)} label="ra.action.create">
        <IconContentAdd/>
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-label={title}>

        <Form
          onSubmit={handleSubmit}
          render={({handleSubmit, form, submitting, pristine, values}) => (
          <Fragment>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
              {children}
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                label="ra.action.create"
                onClick={() => handleSubmit(values)}>
                {loading
                  ? (<CircularProgress size={18} thickness={2}/>)
                  : (<IconSave/>)}
              </Button>
              <Button label="ra.action.cancel" onClick={() => setShowDialog(false)}>
                <IconCancel/>
              </Button>
            </DialogActions>
          </Fragment>
        )}/>
      </Dialog>
    </Fragment>
  );
}

export default QuickCreateButton;
