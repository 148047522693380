
import {
  // FirebaseRealTimeSaga,
  FirebaseDataProvider,
  FirebaseAuthProvider
} from "react-admin-firebase";
import * as firebase from "firebase/app";
import { firebaseConfig as config } from '../FIREBASE_CONFIG';

export const firebaseApp = firebase.initializeApp(config);

var db = firebaseApp.firestore();
// if (window.location.hostname === "localhost") {
  // Note that the Firebase Web SDK must connect to the WebChannel port
  // db.settings({
  //   host: "localhost:8080",
  //   ssl: false
  // });
// }

const options = {
  logging: true,
  app: firebaseApp,
  rootRef: 'root_collection/some_document',
  watch: ["posts"]
}

const fbProvider = FirebaseDataProvider(config, options);

export const dataProvider = (type, resourceName, params) => {
  console.log(type)
  console.log(resourceName)
  console.log(params)
  return fbProvider(type, resourceName, params)
}
export const authProvider = FirebaseAuthProvider(config, options);
// export const firebaseRealtime = FirebaseRealTimeSaga(dataProvider, options);
