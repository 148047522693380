import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/core/Avatar';
import { List, TextField, DateField, ReferenceField, EditButton, RichTextField } from "react-admin";


const cardStyle = {
  flex: 1,
};

const mediaStyle = {
  height: 140,
};

const PostGrid = ({ ids, data, basePath }) => {
  return (
    <div style={{ margin: '0 24px' }}>
      <Grid container spacing={16}>
        {ids.map(id =>
        <Grid item xs={12} sm={4} md={3} alignItems="stretch" alignContent="stretch" direction="column" justify="flex-start" style={{ display: 'flex' }}>
        <Card key={id} style={cardStyle}>
          <CardMedia
            style={mediaStyle}
            image={data[id].banner ? data[id].banner.src : 'https://placehold.it/400x140'}
          />
          <CardContent>
            <Typography gutterBottom variant="title" gutterBottom>
              {data[id].title}
            </Typography>
            <Typography variant="subheading" gutterBottom>
              <DateField record={data[id]} source="createdate" showTime />
            </Typography>
            <Typography variant="body1" color="textSecondary">
              <TextField record={data[id]} source="summary" />
            </Typography>
          </CardContent>
          <CardActions>
            <EditButton resource="posts" basePath={basePath} record={data[id]} />
            {/* <Button size="small" color="primary">
              Share
            </Button>
            <Button size="small" color="primary">
              Learn More
            </Button> */}
          </CardActions>
        </Card>
        </Grid>
      )}
      </Grid>
    </div>
  );
}

// return (
//   <div style={{ margin: '1em' }}>
//   {ids.map(id =>
//       <Card key={id} style={cardStyle}>
//           <CardHeader
//               title={<TextField record={data[id]} source="author.name" />}
//               subheader={<DateField record={data[id]} source="created_at" />}
//           />
//           <CardContent>
//               <TextField record={data[id]} source="body" />
//           </CardContent>
//           <CardContent>
//               about&nbsp;
//               <ReferenceField label="Post" resource="comments" record={data[id]} source="post_id" reference="posts" basePath={basePath}>
//                   <TextField source="title" />
//               </ReferenceField>
//           </CardContent>
//           <CardActions style={{ textAlign: 'right' }}>
//               <EditButton resource="posts" basePath={basePath} record={data[id]} />
//           </CardActions>
//       </Card>
//   )}
//   </div>
// );
PostGrid.defaultProps = {
  data: {},
  ids: [],
};

export default PostGrid;
