import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import { ReferenceInput, SelectInput } from 'react-admin';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import QuickCreateButton from '../components/QuickCreateButton';
import MediaQuickPreviewButton from './MediaQuickPreviewButton';
import {MediaCreateFields} from './';

const optionRenderer = choice => (
  <React.Fragment>
    <ListItemIcon>
      <img src={choice.image.src} style={{width:'30px',height:'30px'}} />
    </ListItemIcon>
    <ListItemText inset primary={choice.image.title} />

  </React.Fragment>
);


const MediaReferenceInput = props => (
  <Fragment>
      <ReferenceInput {...props}>
          <SelectInput optionText={optionRenderer} />
      </ReferenceInput>
      <QuickCreateButton
        source={props.source}
        resource="media"
        title="Upload media"
      >
        <MediaCreateFields />
      </QuickCreateButton>
      {/* We use Field to get the current value of the `post_id` field */}
      <Field
          name={props.source}
          component={({ input }) =>
              input.value && <MediaQuickPreviewButton id={input.value} source={props.source} resource={props.resource} />
          }
      />
  </Fragment>
);

export default MediaReferenceInput;
