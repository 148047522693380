import React from 'react';
import RawPhoneInput from 'react-phone-number-input';
import { debounce } from 'lodash';
import { Field } from 'react-final-form';


// class PhoneInput extends Component {
//   state = {
//     value: ''
//   }

//   render() {
//     // If `country` property is not passed
//     // then "International" format is used.
//     return (
//       <PhoneInput
//         country="US"
//         value={ this.state.value }
//         onChange={ value => this.setState({ value }) } />
//     )
//   }
// }
const PhoneInput = props => (
  <Field
    name={props.source}
    render={props => (
      <RawPhoneInput value={props.input.value} onChange={debounce(props.input.onChange, 0, {leading:false})} />
    )}
    {...props}
  />
);
export default PhoneInput;
