import * as React from "react";
import { PostList, PostShow, PostCreate, PostEdit } from "./posts";
import { CommentList, CommentShow, CommentCreate, CommentEdit } from "./comments";
import { Admin, Resource } from "react-admin";
import CommentIcon from '@material-ui/icons/Comment';
import PostIcon from '@material-ui/icons/LibraryBooks';
import UserIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/People';
import MediaIcon from '@material-ui/icons/PhotoLibrary';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SettingsIcon from '@material-ui/icons/SettingsApplications';
import CustomLoginPage from './CustomLoginPage';

import { AppUserList, AppUserShow, AppUserCreate, AppUserEdit } from './app-users';
import { MediaList, MediaShow, MediaCreate, MediaEdit } from "./media/";
import './App.css';
import 'react-phone-number-input/style.css'
import { dataProvider, authProvider } from "./data";
import { GroupList, GroupShow, GroupCreate, GroupEdit } from "./groups";
import theme from "./theme";
import { NotificationList, NotificationCreate } from "./notifications";

class App extends React.Component {
  render() {
    return (
      <Admin
        theme={theme}
        loginPage={CustomLoginPage}
        dataProvider={dataProvider}
        authProvider={authProvider}
      >
        <Resource
          name="posts"
          icon={PostIcon}
          list={PostList}
          show={PostShow}
          create={PostCreate}
          edit={PostEdit}
        />
        <Resource
          name="media"
          icon={MediaIcon}
          list={MediaList}
          show={MediaShow}
          create={MediaCreate}
          edit={MediaEdit}
        />
        <Resource
          name="groups"
          icon={GroupIcon}
          list={GroupList}
          show={GroupShow}
          create={GroupCreate}
          edit={GroupEdit}
        />
        <Resource
          name="appUsers"
          options={{label: 'Users'}}
          icon={UserIcon}
          list={AppUserList}
          show={AppUserEdit}
          create={AppUserCreate}
          edit={AppUserEdit}
        />
        <Resource
          name="notifications"
          icon={NotificationsIcon}
          list={NotificationList}
          create={NotificationCreate}
        />
      </Admin>
    );
  }
}

export default App;
