// in src/posts.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  Filter,
  SimpleShowLayout,
  SimpleForm,
  ReferenceField,
  ReferenceInput,
  TextField,
  TextInput,
  SingleFieldList,
  ChipField,
  EditButton,
  DeleteButton,
  RichTextField,
  SelectInput,
  FileField,
  FileInput,
  ImageField,
  ImageInput,
  DateField,
  ReferenceArrayField,
  required,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import GroupReferenceInput from "./groups/GroupReferenceInput";
import PhoneInput from "./components/PhoneInput";

const AppUserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="uid" alwaysOn />
  </Filter>
);

export const AppUserList = (props) => (
  <List {...props} filters={<AppUserFilter />}>
    <Datagrid>
      <TextField label="ID Number" source="id" />
      <TextField label="Name" source="displayName" />
      <TextField label="Email Address" source="email" />
      <TextField label="Phone Number" source="phoneNumber" />
      <ReferenceArrayField label="User Groups" source="group_ids" reference="groups">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <EditButton label="EDIT" />
      <DeleteButton label="DELETE" redirect={false}/>
    </Datagrid>
  </List>
);

export const AppUserShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
    <TextField source="id" />
    <TextField source="displayName" />
    <TextField source="email" />
    <TextField source="phoneNumber" />
    </SimpleShowLayout>
  </Show>
);

export const AppUserCreate = (props) => (
  <Create {...props} >
    <SimpleForm redirect="list">
      <TextInput label="ID Number" source="id" validate={required()} />
      <TextInput label="Name" source="displayName" variant="standard" />
      <TextInput label="Email Address" source="email" type="email" variant="standard" />
      <PhoneInput label="Phone Number" source="phoneNumber" />
      <GroupReferenceInput
        label="Groups"
        source="group_ids"
        allowEmpty
        resettable
        reference="groups"
      />
    </SimpleForm>
  </Create>
);

export const AppUserEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect="list">
      <TextInput label="ID Number" source="id" disabled />
      <TextInput label="Name" source="displayName" />
      <TextInput label="Email Address foo" source="email" type="email" options={{ variant: 'standard' }} />
      <PhoneInput label="Phone Number" source="phoneNumber" />
      <GroupReferenceInput
        source="group_ids"
        label="Groups"
        allowEmpty
        resettable
        reference="groups"
      />
    </SimpleForm>
  </Edit>
);
