// in src/Medias.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  SimpleShowLayout,
  SimpleForm,
  TextField,
  TextInput,
  ShowButton,
  EditButton,
  DeleteButton,
  RichTextField,
  SelectInput,
  ImageField,
  ImageInput,
  required,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import MediaGrid from "./MediaGrid";

export const MediaList = (props) => (
  <List {...props} title="Media items">
    <MediaGrid />
    {/* <Datagrid>
      <ImageField source="image.src" />
      <TextField source="image.title" />
      <EditButton label="" />
      <DeleteButton label="" redirect={false}/>
    </Datagrid> */}
  </List>
);

export const MediaShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <ImageField source="image.src" />
      <TextField source="image.title" />
      <TextField disabled source="createdate" />
      <TextField disabled source="lastupdate" />
    </SimpleShowLayout>
  </Show>
);

export const MediaCreateFields = () => (
  <React.Fragment>
    <ImageInput validate={required()} source="image" label="Image" accept="image/*">
      <ImageField source="src" title="title" />
    </ImageInput>
    <TextInput source="image.title" />
  </React.Fragment>
);

export const MediaCreate = (props) => (
  <Create {...props} >
    <SimpleForm>
      <MediaCreateFields />
    </SimpleForm>
  </Create>
);

export const MediaEdit = (props) => (
  <Edit {...props} >
    <SimpleForm>
      <ImageInput source="image" label="Image" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput source="image.title" />
      <TextInput disabled source="id" />
      <TextInput disabled source="createdate" />
      <TextInput disabled source="lastupdate" />
    </SimpleForm>
  </Edit>
);
