// in src/posts.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  Filter,
  SimpleShowLayout,
  SimpleForm,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  TextField,
  TextInput,
  ShowButton,
  EditButton,
  DeleteButton,
  RichTextField,
  SelectInput,
  FileField,
  FileInput,
  ImageField,
  ImageInput,
  DateField,
  ReferenceArrayInput,
  SelectArrayInput,
  required,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import PostGrid from "./components/PostGrid";
import MediaReferenceInput from "./media/MediaReferenceInput";
import GroupReferenceInput from "./groups/GroupReferenceInput";

const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="title" alwaysOn />
  </Filter>
);

export const PostList = (props) => (
  <List {...props} filters={<PostFilter />}>
    <Datagrid>
      <TextField source="title" />
      {/* <TextField source="summary" /> */}
      <DateField source="createdate" showTime />
      <DateField source="lastupdate" showTime />
      {/* <FileField source="file.src" title="file.title" target="_blank" label="Attachment" /> */}
      <ReferenceField source="banner_id" reference="media"><TextField source="image.title" /></ReferenceField>
      {/* <FileField source="banner.src" title="banner.title" target="_blank" label="Banner Image" /> */}
      <EditButton label="EDIT" />
      <DeleteButton label="DELETE" redirect={false}/>
    </Datagrid>
    {/* <PostGrid /> */}
  </List>
);

export const PostShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="createdate" />
      <TextField source="lastupdate" />
      <RichTextField source="body" />
      {/* <TextField source="summary" /> */}
      <FileField source="file.src" title="file.title" label="Attachment" />
      <ImageField source="banner.src" title="banner.title" label="Banner Image" />
    </SimpleShowLayout>
  </Show>
);

export const PostCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="title" />
      <RichTextInput source="body" />
      {/* <TextInput source="summary" /> */}
      {/* <FileInput source="file" label="Attachment" accept="application/pdf">
        <FileField source="src" title="title" />
      </FileInput>
      <ImageInput source="banner" label="Banner Image" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput> */}
      <MediaReferenceInput
        source="banner_id"
        reference="media"
        allowEmpty
        validate={required()}
        perPage={10000}
      />
    </SimpleForm>
  </Create>
);

export const PostEdit = (props) => {
  console.log(props)
  return (
    <Edit {...props}>
      <SimpleForm redirect="list">
        <TextInput disabled source="id" />
        <TextInput disabled source="createdate" />
        <TextInput disabled source="lastupdate" />
        <TextInput source="title" />
        {/* <TextInput source="summary" multiline /> */}
        <RichTextInput source="body" />
        {/* <FileInput source="file" label="Attachment" accept="application/pdf">
          <FileField source="src" title="title" />
        </FileInput>
        <ImageInput source="banner" label="Banner Image" accept="image/*" placeholder={<p>Drop your file here</p>}>
          <ImageField source="src" title="title" />
        </ImageInput> */}
        <MediaReferenceInput
          source="banner_id"
          reference="media"
          allowEmpty
          validate={required()}
          perPage={10000}
        />
      </SimpleForm>
    </Edit>
  );
}
