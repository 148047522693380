// in src/Groups.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  SimpleShowLayout,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
  DeleteButton,
  required,
} from "react-admin";

export const GroupList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="name" />
      <EditButton label="EDIT" />
      <DeleteButton label="DELETE" redirect={false}/>
    </Datagrid>
  </List>
);

export const GroupShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField disabled source="createdate" />
      <TextField disabled source="lastupdate" />
    </SimpleShowLayout>
  </Show>
);

export const GroupCreateFields = () => (
  <TextInput source="name" validate={required()} label="Name" />
);

export const GroupCreate = (props) => (
  <Create {...props} >
    <SimpleForm>
      <GroupCreateFields />
    </SimpleForm>
  </Create>
);

export const GroupEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput disabled source="createdate" />
      <TextInput disabled source="lastupdate" />
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);
