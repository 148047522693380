import React, { Fragment } from 'react';
import { ReferenceArrayInput, SelectArrayInput } from 'react-admin';

import QuickCreateButton from '../components/QuickCreateButton';
import { GroupCreateFields } from './';
import { useForm } from 'react-final-form';

const GroupReferenceInput = props => {
  const form = useForm();
  return (
    <Fragment>
        <ReferenceArrayInput {...props}>
            <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <QuickCreateButton
          source={props.source}
          resource="groups"
          title="Add group"
          onCreate={(newRecord, currentFieldValue) => {
            form.change(props.source, currentFieldValue
              ? [
                ...currentFieldValue,
                newRecord.id
              ]
              : [newRecord.id]);
          }}
        >
          <GroupCreateFields />
        </QuickCreateButton>
    </Fragment>
  );
}

export default GroupReferenceInput;
