import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  SimpleShowLayout,
  SimpleForm,
  TextField,
  TextInput,
  SaveButton,
  DeleteButton,
  required,
  SelectArrayInput,
  ReferenceArrayInput,
  Toolbar,
  useMutation, useNotify, useRedirect, Button
} from "react-admin";
import { useFormState } from 'react-final-form';
import * as firebase from "firebase/app";
import "firebase/functions";

const functions = firebase.app().functions('europe-west1');

const SendButton = ({ record }) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const [sendLoading, setSendLoading] = React.useState(false);

    const formState = useFormState();
    const [send, { loading }] = useMutation(
      {
          type: 'create',
          resource: 'notifications',
          payload: { data: formState.values },
      },
      {
        onSuccess: ({ data }) => {
          notify('Message sent');
          setSendLoading(false);
          redirect('/notifications');
        },
        onFailure: (error) => {
          notify(`Message creation failed: ${error.message}`, 'warning');
          setSendLoading(false);
        },
      }
    );

    const sendNotification = React.useCallback(async () => {
        try {
          setSendLoading(true);

          //firebase.functions().useFunctionsEmulator('http://localhost:5001')

          const sendMessages = functions.httpsCallable('sendMessages');

          const res = await sendMessages({ users: formState.values.user_ids || [], groups: formState.values.group_ids || [], title: formState.values.title, body: formState.values.body });

          console.log(res);

          send();
        } catch (error) {
          notify(`Message delivery failed: ${error.message}`, 'warning')
        }
    });
    return <Button label="Send" onClick={sendNotification} disabled={loading || sendLoading} />;
};

export const NotificationList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="title" />
      <TextField source="body" />
      <DeleteButton label="REMOVE" redirect={false}/>
    </Datagrid>
  </List>
);

export const NotificationCreateFields = () => (
  <>
    <TextInput source="title" validate={required()} label="Title" />
    <TextInput source="body" validate={required()} label="Body" />
  </>
);

const optionRenderer = choice => `${choice.displayName} (ID: ${choice.id})`;

export const NotificationCreate = (props) => (
  <Create {...props} >
    <SimpleForm toolbar={<Toolbar>
      <SendButton />
    </Toolbar>}>
      <TextInput source="title" validate={required()} label="Title" />
      <TextInput source="body" validate={required()} label="Body" multiline />
      <ReferenceArrayInput label="Send to users" source="user_ids" reference="appUsers">
        <SelectArrayInput optionText={optionRenderer} />
      </ReferenceArrayInput>
      <ReferenceArrayInput label="Send to groups" source="group_ids" reference="groups">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);
