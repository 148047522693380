import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/core/Avatar';
import { List, TextField, DateField, ReferenceField, EditButton, RichTextField, DeleteButton } from "react-admin";


const cardStyle = {
  flex: 1,
  margin: '10px',
};

const mediaStyle = {
  height: 140,
};

const MediaGrid = ({ ids, data, basePath }) => {
  return (
    <div style={{ margin: '0 24px' }}>
      <Grid container spacing={16}>
        {ids.map(id =>
        <Grid item xs={12} sm={4} md={3} alignItems="stretch" alignContent="stretch" direction="column" justify="flex-start" style={{ display: 'flex' }}>
        <Card key={id} style={cardStyle}>
          <CardMedia
            style={mediaStyle}
            image={data[id].image ? data[id].image.src : 'https://placehold.it/400x140'}
          />
          <CardContent>
            <Typography gutterBottom variant="title" gutterBottom>
              {data[id].image.title + ' '}
            </Typography>
            <Typography variant="subheading" gutterBottom>
              <DateField record={data[id]} source="createdate" showTime />
            </Typography>
          </CardContent>
          <CardActions>
            <EditButton resource="posts" basePath={basePath} record={data[id]} />
            <DeleteButton resource="posts" basePath={basePath} record={data[id]} label="delete" redirect={false}/>
            {/* <Button size="small" color="primary">
              Share
            </Button>
            <Button size="small" color="primary">
              Learn More
            </Button> */}
          </CardActions>
        </Card>
        </Grid>
      )}
      </Grid>
    </div>
  );
}

MediaGrid.defaultProps = {
  data: {},
  ids: [],
};

export default MediaGrid;
